import { Switch } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const LawSelectionForm = ({ selectedLaws, setSelectedLaws, disableUserDocumentsToggle }) => {
    const { t } = useTranslation();
    const [mode, setMode] = useState('basic');
    const [showAtLeastOneMessage, setShowAtLeastOneMessage] = useState(false);
    const [showMaxTwoMessage, setShowMaxTwoMessage] = useState(false);
    const [showBurgerlijkNotification, setShowBurgerlijkNotification] = useState(false);

    const toggleLaw = (law) => {
        const enabledCount = Object.values(selectedLaws).filter(isEnabled => isEnabled).length;
        // show error message when user tries to toggle last active law
        if (selectedLaws[law] && enabledCount === 1) {
            setShowAtLeastOneMessage(true);
            setTimeout(() => {
                setShowAtLeastOneMessage(false);
            }, 3000);
            return;
        }

        if (!selectedLaws[law] && enabledCount >= 2) {
            setShowMaxTwoMessage(true);
            setTimeout(() => setShowMaxTwoMessage(false), 3000);
            return;
        }

        if (law === 'burgerlijk_wetboek' && selectedLaws.user_documents && !selectedLaws.burgerlijk_wetboek) {
            setShowBurgerlijkNotification(true);
            setTimeout(() => setShowBurgerlijkNotification(false), 6000);
        }

        setSelectedLaws(prev => ({
            ...prev,
            [law]: !prev[law],
        }));
    };

    const changeMode = (newMode) => {
        if (newMode === 'basic') {
            setSelectedLaws(prevLaws => {
                const resetLaws = { ...prevLaws };
                const basicLaws = ['burgerlijk_wetboek', 'besluit_kleine_herstellingen', 'besluit_servicekosten', 'user_documents'];
                let anyBasicLawActive = basicLaws.some(law => resetLaws[law]);

                Object.keys(resetLaws).forEach(law => {
                    if (!basicLaws.includes(law)) {
                        resetLaws[law] = false;
                    }
                });

                // Ensure at least one basic law is active if none were
                if (!anyBasicLawActive) {
                    resetLaws['burgerlijk_wetboek'] = true;
                }

                return resetLaws;
            });
        }
        setMode(newMode);
    };

    const lawOptions = {
        basic: ['burgerlijk_wetboek', 'besluit_kleine_herstellingen', 'besluit_servicekosten', 'user_documents'],
        advanced: Object.keys(selectedLaws),
    };

    return (
        <div className='max-w-1/6 min-w-1/6 mx-auto border border-border rounded-lg p-3 bg-white shadow-md'>
            <form>
                <fieldset>
                    <legend className="text-sm font-medium  text-copy-900 flex justify-between items-center w-full">
                        {t('chatbot.options')}
                        <select
                            value={mode}
                            onChange={(e) => changeMode(e.target.value)}
                            className="ml-2 w-32 text-sm p-1 bg-white border border-gray-300 rounded-md"
                        >
                            <option value="basic">{t('chatbot.mode.basic')}</option>
                            <option value="advanced">{t('chatbot.mode.advanced')}</option>
                        </select>
                    </legend>
                    {mode === 'advanced' && (
                        <div className="text-xs text-gray-600 mt-3 mb-3 w-44 overflow-hidden text-ellipsis whitespace-normal">
                            {t('chatbot.mode.advanced_explanation')}
                        </div>
                    )}

                    {showAtLeastOneMessage && (
                        <div className="text-left text-xs py-2 font-small text-error message-animation" style={{ opacity: showAtLeastOneMessage ? 1 : 0 }}>
                            {t('chatbot.at_least_one')}
                        </div>
                    )}
                    {showMaxTwoMessage && (
                        <div className="text-left text-xs py-2 font-small text-error message-animation" style={{ opacity: showMaxTwoMessage ? 1 : 0 }}>
                            {t('chatbot.max_two_laws')}
                        </div>
                    )}
                    {showBurgerlijkNotification && (
                        <div className="text-left text-xs max-w-48 w-48 overflow-hidden py-2 font-small text-copy-700 whitespace-normal message-animation" style={{ opacity: showBurgerlijkNotification ? 1 : 0 }}>
                            {t('chatbot.burgerlijk_wetboek_notification')}
                        </div>
                    )}

                    <div className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
                        {lawOptions[mode].map((law, idx) => (
                            <div key={idx} className={`relative flex items-center py-2`}>
                                <div className="flex-1 min-w-0 text-xs leading-5 pr-2">
                                    <label htmlFor={`law-toggle-${law}`} className="select-none font-small break-words text-gray-900">
                                        {t(`chatbot.law_selection.${law}`)}
                                    </label>
                                </div>
                                <div className="flex-shrink-0">
                                    <Switch
                                        id={`law-toggle-${law}`}
                                        name={`law-toggle-${law}`}
                                        checked={selectedLaws[law]}
                                        disabled={law === 'user_documents' && disableUserDocumentsToggle}
                                        onChange={() => toggleLaw(law)}
                                        className={`${selectedLaws[law] ? 'bg-primary-600' : 'bg-gray-200'}
                                    relative inline-flex h-4 w-8 cursor-pointer rounded-full border border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2`}
                                    >
                                        <span className="sr-only">Toggle {law}</span>
                                        <span
                                            aria-hidden="true"
                                            className={`${selectedLaws[law] ? 'translate-x-4' : 'translate-x-0'}
                                        pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                                        />
                                    </Switch>
                                </div>
                            </div>
                        ))}
                    </div>
                </fieldset>
            </form>
        </div>
    );
};

export default LawSelectionForm;
