import React from 'react';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const CompanyMission = () => {
  const { t } = useTranslation(); // Initialize useTranslation hook

  return (
      <div className="flex flex-col mt-[10vh] items-center">
        <div className="max-w-4xl w-full mx-auto mb-4 border border-border rounded-lg p-4 bg-white shadow-md">
          <h1 className="text-3xl mb-3">{t('companyMission.title')}</h1>
        </div>
        <div className="max-w-4xl w-full mx-auto mb-4 border border-border rounded-lg p-4 bg-white shadow-md">
          <p className="mb-4">{t('companyMission.paragraph1')}</p>
        </div>
        <div className="max-w-4xl w-full mx-auto mb-4 border border-border rounded-lg p-4 bg-white shadow-md">
          <p className="mb-4">{t('companyMission.paragraph2')}</p>
        </div>
        <div className="max-w-4xl w-full mx-auto mb-4 border border-border rounded-lg p-4 bg-white shadow-md">
          <p className="mb-4">{t('companyMission.paragraph3')}</p>
          <p className="mb-4">{t('companyMission.paragraph4')}</p>
        </div>
      </div>
  );
};

export default CompanyMission;
