import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ContactPage() {
    const { t } = useTranslation();

    return (
        <div className="mt-[10vh] items-center">
            <div className="max-w-4xl mx-auto border border-border rounded-lg p-4 bg-white shadow-md overflow-y-auto">
                <div className="px-6 py-24 sm:py-32 lg:px-8">
                    <div className="mx-auto max-w-2xl text-center">
                        <h2 className="text-3xl font-bold tracking-tight text-copy-900 sm:text-4xl">{t('contactPage.title')}</h2>
                        <p className="mt-2 text-lg leading-8 text-copy-600">
                            {t('contactPage.description')}
                        </p>
                    </div>
                    <div className="mt-16 max-w-xl mx-auto sm:mt-20">
                        <div className="text-center">
                            <p className="text-lg text-copy-800">{t('general.email')}: <a href="mailto:info@hurai.nl" className="text-primary-600 hover:text-primary-700">info@hurai.nl</a></p>
                            <p className="text-lg text-copy-800">{t('general.whatsapp')}: <a href="https://api.whatsapp.com/send?phone=31642419282" className="text-green-600 hover:text-green-700">+316 4241 9282</a></p>
                            <p className="mt-4 text-lg text-copy-800">{t('contactPage.kvkInfo')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
