import { Disclosure } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import gunes from './assets/gunes.jpg'
import wout from './assets/wout.jpg'

const people = [
    {
      name: 'Günes van Dijk',
      id: "gunes",
      role: 'Co-Founder / CEO',
      imageUrl: gunes,
      linkedIn: 'https://www.linkedin.com/in/gunesvandijk/',
    },
    {
      name: 'Wout Konings',
      id: "wout",
      role: 'Co-Founder / CTO',
      imageUrl: wout,
      linkedIn: 'https://www.linkedin.com/in/woutkonings/',
    },
  ]
  
  export default function TeamPage() {
    const { t } = useTranslation(); 
    return (
      <div className="flex mt-[10vh] items-center">
        <div className="mx-auto border border-border rounded-lg p-4 bg-white shadow-md overflow-y-auto py-24 md:py-">
          <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-5">
            <div className="max-w-2xl xl:col-span-2">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t('teamPage.aboutTeamTitle')}</h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                {t('teamPage.teamDescription')}
              </p>
            </div>
            <ul className="-mt-12 space-y-12 divide-y divide-gray-200 xl:col-span-3">
              {people.map((person) => (
                <li key={person.name} className="flex flex-col gap-10 pt-12 sm:flex-row">
                  <img className="aspect-[4/5] h-60 flex-none rounded-2xl object-cover grayscale" src={person.imageUrl} alt="" />
                  <div className="max-w-xl flex-auto">
                    <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
                    <p className="text-base leading-7 text-copy-800">{person.role}</p>
                    <p className="mt-6 text-base leading-7 text-copy-800">{t(`teamPage.bios.${person.id}.shortBio`)}</p>
  
                    <Disclosure as="div" key={person.name} className="pt-6">
                      {({ open }) => (
                        <>
                          <dt>
                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                              <span className="text-xs text-copy-300 leading-7">{t('teamPage.moreInfo')}</span>
                              <span className="ml-6 flex h-7 items-center">
                                {open ? (
                                  <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                ) : (
                                  <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                )}
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="mt-2 pr-12 text-copy-800">
                            {t(`teamPage.bios.${person.id}.largeBio`)}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>

                  <ul  className="mt-6 flex gap-x-6">
                    <li>
                      <a href={person.linkedIn} target="_blank" rel="noreferrer" className="text-gray-400 hover:text-gray-500">
                        <span className="sr-only">LinkedIn</span>
                        <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                          <path
                            fillRule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            ))}
          </ul>
        </div>
        </div>
      </div>
    )
  }
  