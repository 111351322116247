import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from './assets/logo-text-only-color.svg'

const LandingPage = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white mx-auto border border-border rounded-lg shadow-md">
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-primary-100/20">
        <div className="mx-auto pb-24 pt-10 sm:pb-32 lg:grid lg:grid-cols-7 lg:gap-x-8 lg:pl-8 lg:py-40">
          <div className="px-6 lg:px-0 lg:pt-4 col-span-3">
            <div className="mx-auto max-w-3xl ml-4">
              <div className="max-w-3xl">
                <div className="flex items-center h-24">
                  <img
                    className="h-full w-auto mr-4"
                    src={logo}
                    alt="Hurai"
                  />
                </div>
                <div className="mt-24 sm:mt-32 lg:mt-16">
                  <span className="rounded-full bg-primary-600/10 px-3 py-1 text-sm leading-6 text-primary-600 ring-1 ring-inset ring-primary-600/10">
                    {t('landingPage.whatsNew')}
                  </span>
                </div>
                <h1 className="mt-10 text-4xl tracking-tight font-black text-primary-600 sm:text-6xl relative">
                  {t('landingPage.titlePart1')}
                  <span className="highlightwordlargered">
                    {t('landingPage.titleHighlightedWord1')}
                  </span>
                  <span className="highlightwordlargered">
                    {t('landingPage.titleHighlightedWord2')}
                  </span>
                  {t('landingPage.titlePart2')}
                </h1>

                <p className="mt-6 text-2xl leading-8 text-gray-600">
                  {t('landingPage.description')}
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <Link
                    to="/login"
                    className="rounded-md btn-main px-3.5 py-2.5"
                  >
                    {t('landingPage.getStarted')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20 sm:mt-24 md:mx-auto md:max-w-full lg:mx-0 lg:mt-0 lg:w-screen col-span-4">
            <div className="shadow-lg md:rounded-l-3xl">
              <div className="aspect-w-16 aspect-h-9">
                <video
                  src="https://huraisiteassets.blob.core.windows.net/siteassets/hurai_demo_video.m4v"
                  autoPlay
                  loop
                  title="Hurai Demo Video"
                  className="w-full h-full md:rounded-l-3xl"
                ></video>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
    </div>
  );
};

export default LandingPage;
