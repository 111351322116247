import React from 'react';

function ModelToggle({ modelVersion, setModelVersion }) {
  return (
    <div className='max-w-1/6 min-w-1/6 mx-auto border border-border rounded-lg p-3 bg-white shadow-md'>
      <form>
        <fieldset>
          <legend className="text-sm font-medium text-copy-900 flex justify-between items-center w-full">
            Model Selection
          </legend>
          <div className="mt-2 divide-y divide-gray-200">
            <div className="relative flex items-center py-2">
              <div className="flex-1 min-w-0 text-xs leading-5 pr-2">
                <label htmlFor="model-toggle-3" className="select-none font-small break-words text-gray-900">
                  GPT 3
                </label>
              </div>
              <div className="flex-shrink-0">
                <input
                  id="model-toggle-3"
                  type="radio"
                  name="model"
                  value="3"
                  checked={modelVersion === '3'}
                  onChange={() => setModelVersion('3')}
                  className="cursor-pointer rounded-full border-gray-300 text-primary-600 focus:ring-primary-500"
                />
              </div>
            </div>
            <div className="relative flex items-center py-2">
              <div className="flex-1 min-w-0 text-xs leading-5 pr-2">
                <label htmlFor="model-toggle-4" className="select-none font-small break-words text-gray-900">
                  GPT 4
                </label>
              </div>
              <div className="flex-shrink-0">
                <input
                  id="model-toggle-4"
                  type="radio"
                  name="model"
                  value="4"
                  checked={modelVersion === '4'}
                  onChange={() => setModelVersion('4')}
                  className="cursor-pointer rounded-full border-gray-300 text-primary-600 focus:ring-primary-500"
                />
              </div>
            </div>
            <div className="relative flex items-center py-2">
              <div className="flex-1 min-w-0 text-xs leading-5 pr-2">
                <label htmlFor="model-toggle-4o" className="select-none font-small break-words text-gray-900">
                  GPT 4o
                </label>
              </div>
              <div className="flex-shrink-0">
                <input
                  id="model-toggle-4o"
                  type="radio"
                  name="model"
                  value="4"
                  checked={modelVersion === '4o'}
                  onChange={() => setModelVersion('4o')}
                  className="cursor-pointer rounded-full border-gray-300 text-primary-600 focus:ring-primary-500"
                />
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  );
}

export default ModelToggle;
