// Signup.js
import React, { useState } from 'react';
import { auth } from './Firebase';
import api from './Api';
import { signInWithPopup, GoogleAuthProvider, OAuthProvider, deleteUser } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import logo from './assets/logo-text-only-color.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import { useUser } from './UserContext'; // Import the useUser hook

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [phone, setPhone] = useState('');
  const [organization, setOrganization] = useState('');
  const [functionTitle, setFunctionTitle] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptContact, setAcceptContact] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setIsUserLoading } = useUser();

  const googleProvider = new GoogleAuthProvider();
  const microsoftProvider = new OAuthProvider('microsoft.com');


  const sendUserDataToBackend = async (user_data) => {
    try {
      await api.post('/users', user_data);
    } catch (error) {
      console.error('Error saving data:', error);
      setError(t('signup.errorSavingData'));
      throw error;
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    // Check if passwords match. If not, set an error message.
    if (confirmPassword && confirmPassword !== newPassword) {
      setPasswordError('Passwords do not match.');
    } else {
      setPasswordError(''); // Clear error message when passwords match
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    // Check if passwords match. If not, set an error message.
    if (password && password !== newConfirmPassword) {
      setPasswordError('Passwords do not match.');
    } else {
      setPasswordError(''); // Clear error message when passwords match
    }
  };

  const handleSignupWithEmail = async (e) => {
    e.preventDefault();
    if (!acceptTerms) {
      setError(t('signup.mustAcceptTerms'));
      return;
    }
    if (password !== confirmPassword) {
      setError(t('signup.passwordsMustMatch'));
      return;
    }
    try {
      setIsUserLoading(true);
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userData = {
        uid: userCredential.user.uid,
        email,
        phone,
        organization,
        functionTitle,
        auth_type: 'email',
        accept_terms: acceptTerms,
        accept_contact: acceptContact,
      }
      await sendUserDataToBackend(userData);
      setIsUserLoading(false);
    } catch (error) {
      console.error('Signup Error:', error);
      if (error.code === 'auth/email-already-in-use') {
        setError(t('signup.emailAlreadyInUse'));
      } else {
        setError(t('signup.error'));
        // Delete the Firebase user if the backend call fails
        if (auth.currentUser) {
          console.log("Deleting user from Firebase as user creation in backend failed")
          await deleteUser(auth.currentUser);
        }
      }
    }
    finally {
      setIsUserLoading(false);
    }
    navigate('/');
  };

  const handleOAuthSignup = async (provider) => {
    if (!acceptTerms) {
      setError(t('signup.mustAcceptTerms'));
      return;
    }
    try {
      setIsUserLoading(true); 
      const result = await signInWithPopup(auth, provider);
      const userData = {
        uid: result.user.uid,
        email: result.user.email,
        phone,
        organization,
        functionTitle,
        auth_type: provider.providerId,
        accept_terms: acceptTerms,
        accept_contact: acceptContact,
      }
      await sendUserDataToBackend(userData);
      setIsUserLoading(false);
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setError(t('signup.emailAlreadyInUse'));
      } else {
        console.error('Error signing in with provider:', error);
        setError(t('signup.errorProvider'));
        // Delete the Firebase user if the backend call fails
        if (auth.currentUser) {
          console.log("Deleting user from Firebase as user creation in backend failed")
          await deleteUser(auth.currentUser);
        }
      }
    }
    finally {
      setIsUserLoading(false);
    }
    navigate('/');
  };


  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={logo} alt="Your Company" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{t('signup.title')}</h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">


          <div className="mt-6 mb-6 grid grid-cols-2 gap-3">
            <div>
              <button
                onClick={() => handleOAuthSignup(googleProvider)}
                className="w-full justify-center rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <div className='flex items-center'>
                  <FontAwesomeIcon icon={faGoogle} />
                  {t('signup.withGoogle')}
                </div>
              </button>
            </div>
            <div>
              <button
                onClick={() => handleOAuthSignup(microsoftProvider)}
                className="w-full justify-center rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <div className='flex items-center'>
                  <FontAwesomeIcon icon={faMicrosoft} />
                  {t('signup.withMicrosoft')}
                </div>
              </button>
            </div>
          </div>
          <form className="space-y-6" onSubmit={handleSignupWithEmail}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                {t('general.email')} <span className='text-red-500 ml-1'>*</span>
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-500 sm:text-sm"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                {t('general.password')} <span className='text-red-500 ml-1'>*</span>
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-500 sm:text-sm"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </div>
            </div>

            <div>
              <label htmlFor="confirm-password" className="block text-sm font-medium leading-6 text-gray-900">
                {t('signup.confirmPassword')} <span className='text-red-500 ml-1'>*</span>
              </label>
              <div className="mt-1">
                <input
                  id="confirm-password"
                  name="confirm-password"
                  type="password"
                  autoComplete="new-password"
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-500 sm:text-sm"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
                {passwordError && <p className="mt-2 text-sm text-red-600">{passwordError}</p>}
              </div>
            </div>


            <div>
              <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                {t('general.phone')} 
                {/* <span className='text-red-500 ml-1'>*</span> */}
              </label>
              <div className="mt-1">
                <input
                  id="phone"
                  name="phone"
                  type="tel"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-500 sm:text-sm"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            
            <div>
              <label htmlFor="organization" className="block text-sm font-medium leading-6 text-gray-900">
                {t('signup.organization')} 
              </label>
              <div className="mt-1">
                <input
                  id="organization"
                  name="organization"
                  type="text"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-500 sm:text-sm"
                  value={organization}
                  onChange={(e) => setOrganization(e.target.value)}
                />
              </div>
            </div>
            
            <div>
              <label htmlFor="functionTitle" className="block text-sm font-medium leading-6 text-gray-900">
                {t('signup.functionTitle')}
              </label>
              <div className="mt-1">
                <input
                  id="functionTitle"
                  name="functionTitle"
                  type="text"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-500 sm:text-sm"
                  value={functionTitle}
                  onChange={(e) => setFunctionTitle(e.target.value)}
                />
              </div>
            </div>

            <div className="flex items-start">
              <input
                id="accept-terms"
                name="accept-terms"
                type="checkbox"
                required
                className="h-4 w-4 mt-1 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                checked={acceptTerms}
                onChange={(e) => setAcceptTerms(e.target.checked)}
              />
              <label htmlFor="accept-terms" className="ml-2 block text-sm leading-6 text-gray-900">
                {t('signup.accept')}{' '}
                <Link to="/terms" className="text-primary-600 hover:text-primary-500">
                  {t('general.terms')}
                </Link>{' '}
                {t('general.and')}{' '}
                <Link to="/privacy" className="text-primary-600 hover:text-primary-500">
                  {t('general.privacyPolicy')}
                </Link>.
                <span className='text-red-500 ml-1'>*</span>
              </label>
            </div>

            {error && <div className="text-sm text-red-600">{error}</div>}

            <div className="flex items-center">
              <input
                id="accept-contact"
                name="accept-contact"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                checked={acceptContact}
                onChange={(e) => setAcceptContact(e.target.checked)}
              />
              <label htmlFor="accept-contact" className="ml-2 block text-sm leading-6 text-gray-900">
                {t('signup.acceptContact')}
              </label>
            </div>


            <div>
              <button
                type="submit"
                className="w-full flex justify-center rounded-md bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                {t('signup.button')}
              </button>
            </div>
          </form>

          

          {/* Add navigation to Login page */}
          <div className="mt-6 text-center">
            {t('signup.alreadyHaveAccount')}{' '}
            <Link to="/login" className="font-medium text-primary-600 hover:text-primary-500">
              {t('signup.login')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
